<template>
<div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
  <div id="page-content"  v-if="!viLoader">
    <div class="webNew-dispflex">
       
       <!-- <div class="backbox" style="margin-left: 3px;">
     <div class="back-arrow" @click="previousRoute">
       <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
    
     
     </div>

       </div> -->
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        CERTIFICATE REQUEST
       </h4>
     </div>
	<div class="row mt-4" style="padding-left: 15px;
    padding-right: 15px;">				
		<div class="col-lg-12">
			<div class="widjet">
				<div class="widjethdr dispflex" style="margin-top: 1%;border-bottom: 0px solid #efefef;">
					<div style="width:auto">
					
					</div>
           <div style="width: 21%" class="flexitm">
                <span class="has-float-label">
                  <select :disabled="tcCount > 0 && bcCount > 0 && ccCount > 0"
                    class="form-control form-input"
                    id="deptlistid"
                    v-model="selectOption"
                    @change="showCertModel(selectOption)"
                   
                  > 
                    <option value=''>--Select--</option>
                    <!-- <option :disabled="tcCount > 0" style="	cursor:no-drop;" value='Transfer Certificate'>Transfer Certificate</option>
                    <option :disabled="bcCount > 0" style="	cursor:no-drop;" value='Bonafide Certificate'>Bonafide Certificate</option>
                    <option :disabled="ccCount > 0" value='Conduct Certificate'>Conduct Certificate</option> -->

                        <!-- optionList: ['Transfer Certificate','Bonafide Certificate','Conduct Certificate'], -->

                    <option
                      v-for="(item, index) in optionList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option> 
                  </select>
                  <label for="acyearid"
                    >Create Certificate Request <span class="required">*</span></label
                  >
                </span>
                
              </div>
          <!-- <span v-if="!editId && showContent" class="required">* Note: Please apply for online TC in schools to see the data here...</span> -->
					 <div class="mt-1">
              
                

                <!-- <button id="btnSt" :disabled="tcCount > 0" class="btn gry_bg tooltipt mpopup twoicn" @click="showTCModel" >
                  <i class="fas fa-user-graduate user1"></i>
                  <p class="user2"><i class="fas fa-plus"></i></p>
                  <span class="tooltiptext tooltiptextbtm">TC Certificate Requests</span>
                </button>
                <button id="btnSt" :disabled="ccCount > 0" class="btn gry_bg tooltipt mpopup twoicn" @click="showCCModel">
                  <i class="fas fa-user-graduate user1"></i>
                  <p class="user2"><i class="fas fa-plus"></i></p>
                  <span class="tooltiptext">Conduct Certificate Request</span>
                </button>
                <button id="btnSt" :disabled="bcCount > 0" class="btn gry_bg tooltipt mpopup twoicn" @click="showBCModel">
                  <i class="fas fa-user-graduate user1"></i>
                  <p class="user2"><i class="fas fa-plus"></i></p>
                  <span class="tooltiptext">Bonafide Certificate Request</span>
                </button> -->
           </div>						
				</div>
			<div class="tbllist" style="margin-right: 10px;margin-left: 10px;" >
                  <div class="restable">
                    <div class="resrow resheader">
                      <!-- <div class="cell">S.No.</div> -->
                      <div class="cell">Certificate Request ID</div>
                      <div class="cell">Certificate Request Type</div>
                      <div class="cell">Created By</div>
                      <div class="cell">Status</div>
                  
                      <div class="cell">Action</div>
                    </div>
                    <div class="resrow" v-for="(data, index) in certificateList" :key="index" >
                      <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                      <div class="cell" data-title="Candidate Name">
                        {{ data.tcID }}
                      </div>
                       <div class="cell" data-title="Candidate Name">
                        {{ data.certificateType }}
                      </div>
                       <div class="cell" data-title="Candidate Name">
                        {{ data.requestCreatedBy || '' }}
                      </div>
                       <div class="cell" data-title="Candidate Name">
                        {{ data.status }}
                      </div>
                      <div class="cell">
                        <button :disabled="data.status == 'Approved' || data.status == 'Generated' || data.certificateType == 'Bonafide Certificate' || data.certificateType == 'Conduct Certificate' "
								                @click.prevent="editPopUp(index)"
                                  class="btn round orange tooltipt"
                                >
                                  <i class="fas fa-edit"></i
                                  ><span class="tooltiptext">Edit</span>
                                </button>
                      <button type="button" v-if="data.status == 'Generated' || data.status == 'Online' || data.status == 'Offline'" class="btn round lblue tooltipt" id="svbtn" @click.prevent="printPreview(index)">
                        <i class="fas fa-file-export"></i> <span class="tooltiptext">Generate TC</span></button>
                      </div>
                     
                     
                    </div>
                  </div>
                </div>
				
			</div>
		</div>				
	</div>
  </div>
  <div>

  <b-modal id="openPopup" no-close-on-backdrop no-close-on-esc >
    <div class="widjethdr dispflex">
              <div style="width: auto">
               
                <h3>Transfer Certificate Request</h3>
                <br />
              </div>
             
             
            </div>
      <div class="widjetcontent">							
					<form data-vv-scope="academicyr" id="academicyr" accept-charset="utf-8">				
						<div class="form-row p-2">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3" style="font-weight: bold;">						
							Parent Section
							</div>
               <div class="form-group input-group col-md-6 pr-md-3 mt-3" style="font-weight: bold;">						
							School Admin[Read Only]
							</div>
							<div class="form-group input-group col-md-6 pr-md-3 mt-3">						
								<span class="has-float-label">
									<select
                  :disabled="editId && ( onlineTcRequest.status != 'Submitted' && onlineTcRequest.status != 'Need further Info' && onlineTcRequest.status != 'Rejected' )"
									   v-validate="'required'"
										class="form-control form-input"
										id="tctypeid"
										name="tctype"
										v-model="onlineTcRequest.type"
										
										>
										<option :value="null">--Select--</option>
										<option
											v-for="(item, index) in typeList"
											:key="index"
											:value="item"
										>
											{{ item }}
										</option>
									</select>
									<label
										v-if="errors.first('academicyr.tctype')"
										class="vi-error"
										for="pwdid"
										style="top:-1em"
										>Type is required</label
										>

									<label v-else for="tctypeid">Type <span class="required">*</span></label>
									
								</span>
							</div>
							<div class="form-group input-group col-md-6 mt-3 ">						
								<span class="has-float-label">
									<input type="text" disabled name="currentbranch" v-model="onlineTcRequest.schoolName" class="form-control form-input" id="curnbranid"   />
									<label for="curnbranid">Current School/Branch <span class="required">*</span></label>
								</span>
							</div>
              <div class="form-group input-group col-md-6  mt-2 ">		
								<span class="has-float-label">
									<select
										class="form-control form-input"
										id="reasonid"
										name="reason"
                    :disabled="editId && ( onlineTcRequest.status != 'Submitted' && onlineTcRequest.status != 'Need further Info' && onlineTcRequest.status != 'Rejected' )"
										v-validate="'required'"
										v-model="onlineTcRequest.reason"
										
										>
										<option :value="null">--Select--</option>
										<option
											v-for="(item, index) in reasonList"
											:key="index"
											:value="item"
										>
											{{ item }}
										</option>
									</select>
									<label
										v-if="errors.first('academicyr.reason')"
										class="vi-error"
										for="pwdid"
										style="top:-1em"
										>Reason for Transfer is required</label
										>
									<label v-else for="reasonid">Reason for Transfer <span class="required">*</span></label>
									
								</span>
							</div>
						
							<div class="form-group input-group col-md-6 mt-3">						
								<span class="has-float-label">
									<select
									   disabled
										class="form-control form-input"
										id="statuslistid"
										name="statuslist"
										v-model="onlineTcRequest.status"
										
										>
										<option :value="null">--Select--</option>
										<option
											v-for="(item, index) in statusList"
											:key="index"
											:value="item"
										>
											{{ item }}
										</option>
									</select>
									<label for="statuslistid">Status <span class="required">*</span></label>
									
								</span>
							</div>
              	<div class="form-group input-group col-md-6  mt-md-3">
								<span class="has-float-label">
									<textarea :disabled="editId && ( onlineTcRequest.status != 'Submitted' && onlineTcRequest.status != 'Need further Info' && onlineTcRequest.status != 'Rejected' )" v-validate="'required'" name="comment"  class="form-control form-input" style="min-height:60px;resize:none" id="tocmts" v-model="onlineTcRequest.comments"></textarea>
									<label
										v-if="errors.first('academicyr.comment')"
										class="vi-error"
										for="pwdid"
										style="top:-1em"
										>Comments is required</label
										>
									<label v-else for="tocmts">Comments <span class="required">*</span></label>
								</span>
							</div>
              	<div class="form-group input-group col-md-6  mt-md-3">
								<span class="has-float-label">
									<textarea disabled class="form-control form-input" style="min-height:60px;resize:none" id="tocmts" v-model="onlineTcRequest.statusComments"></textarea>
								
									<label for="tocmts">Status Comments <span class="required">*</span></label>
								</span>
							</div>
              	<!-- <div class="form-group input-group col-md-6  mt-3">						
								<span class="has-float-label">
									<input type="text" disabled name="cityname" v-model="onlineTcRequest.address" class="form-control form-input" id="citynameid"  />
									<label for="citynameid">Address </label>
								</span>
							</div> -->
              
							<!-- <div class="form-group input-group col-md-6 mt-3" v-if="onlineTcRequest.type == 'Other School'">						
								<span class="has-float-label">
									<input list="tostatelist" disabled v-model="onlineTcRequest.state" class="form-control form-input" id="tostatelistid" >
									<label for="tostatelistid">State <span class="required">*</span></label>
									
								</span>
							</div> -->
							<!-- <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="onlineTcRequest.type == 'Other School'">						
								<span class="has-float-label">
									<input  disabled v-model="onlineTcRequest.country" class="form-control form-input" id="countrylistid" >
									<label for="countrylistid">Country <span class="required">*</span></label>
									
								</span>
							</div> -->
							
						
						</div>
             <div class="form-row p-2" v-if="onlineTcRequest.document.path">
              <div class="form-group input-group col-md-4 mt-3">
                      <label>
                                  <span>Upload Document <span class="required">*</span></span>
                              </label>
                 </div>
                  <div class="form-group input-group col-md-6 mt-3">
                            <button class="btn btn-doc btn-sm" style="background-color: darkseagreen;" @click.prevent="preViewDocument(onlineTcRequest.document.path)" v-if="onlineTcRequest.document.path">
                                      View
                            </button>
                           
              </div>
            </div>
					</form>
				</div>	
				<div class="widjetfooter">	
					
					<div class="text-center dk_iconsml">
						<button type="button" v-if="!editId" class="btn btnsml" id="svbtn" @click.prevent="createOnlineTc">  <span>Submit</span></button>
						<button type="button" v-if="editId && ( onlineTcRequest.status == 'Submitted' || onlineTcRequest.status == 'Need further Info' || onlineTcRequest.status == 'Rejected' )" class="btn btnsml" id="svbtn" @click.prevent="updateOnlineTc">  <span>Update</span></button>
						<!-- <button type="button" v-if="onlineTcRequest.status == 'Online'" @click.prevent="printPreview" class="btn btnsml" id="svbtn" >  <span>Print</span></button> -->
						<button type="button"  class="btn btnsml" id="svbtn" @click.prevent="cancelPopUp">  <span>Cancel</span></button>
						
											 
					</div>
				</div>
  </b-modal>
  <b-modal id="CCPopUp" no-close-on-backdrop no-close-on-esc>
    <button type="button" class="close"  @click.prevent="closeCCPopUp">×</button>

      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Conduct Certificate Request Confirmation</h2>
        <br />
        Are you sure you want to Conduct Certificate?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            type="button"
            class="btn btnsml"
            id="svbtn"
            style="width: 12%;"
            @click.prevent="createRequestAction('Conduct Certificate')"
          >
              <span>Confirm</span>
          </button>
          <button @click.prevent="closeCCPopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
    <b-modal id="BCPopUp" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeBCPopUp">×</button>

      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Bonafide Certificate Request Confirmation</h2>
        <br />
        Are you sure you want to Bonafide Certificate?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            type="button"
            class="btn btnsml"
            id="svbtn"
            style="width: 12%;"
            @click.prevent="createRequestAction('Bonafide Certificate')"
          >
              <span>Confirm</span>
          </button>
          <button @click.prevent="closeBCPopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
    <b-modal id="printAction">
          
          <div class="row mt-3">
            
              <div class="col-lg-12 ">
                <div class="widjet py-3 pl-3 pr-3">
                  <div class="row">
                    <div class="col-md-3 text-center">
                        <img width="200" height="120" v-if="schoolDetails.image && schoolDetails.image.path"
                          :src="schoolDetails.image.path"
                          :alt="schoolDetails.accountName"
                          :title="schoolDetails.accountName"
                        />
                        <img width="200" height="120" v-else
                          :src="schoolDetails.image"
                          :alt="schoolDetails.accountName"
                          :title="schoolDetails.accountName"
                        />

                        <!-- <img src="../../assets/img/velammalLogo.png"> -->
                    </div>
                    <div class="col-md-9 text-center">
                      <h1>{{ schoolDetails.accountName }}</h1>
                      <p>{{ schoolDetails.address }}, {{ schoolDetails.street }}, {{ schoolDetails.district ? schoolDetails.district : '' }}, {{ schoolDetails.state ? schoolDetails.state : '' }}, {{ schoolDetails.pincode }}, {{ schoolDetails.country }}<br/><b>Affiliate No. 223434534</b>
                      <br/><b v-if="schoolDetails.landlineNo">P : </b> {{ schoolDetails.landlineNo ? ( '044 -' + ' ' + schoolDetails.landlineNo ) : '' }} | <b>E :</b> <a v-if="schoolDetails.email" href="mailto:info@sairamgroup.in">{{ schoolDetails.email }}</a> | <b>W : </b><a :href="schoolDetails.website" target="_black" v-if="schoolDetails.website">{{ schoolDetails.website }}</a>
                      </p>
                    </div>
                  </div>
                  <div class="dispflex p-2" style="background:#f7f7f7">
                        <div style="width:15%"><b>Date : {{ new Date() | dateFormat }} </b></div>
                        <b>TRANSFER CERTIFICATE</b>
                      <div style="width:25%"> <b>Application No: <input disabled v-model="contactDetails.rollNoAllocated" name="deadLine" id="addlid" > </b></div>
                  </div>
                  <div class="py-3 onlinetc">
                      <div class="dispflex" style="justify-content:flex-start">
                          <div class="lblitem"><label>Name of Student:</label></div>
                          <div class="fielditem"><span>{{ contactDetails.firstName + " " +  contactDetails.lastName }} </span></div>
                      </div>
                      <div class="dispflex" style="justify-content:flex-start">
                          <div class="lblitem"><label>Date of Birth:</label></div>
                          <div class="fielditem"><span>{{ contactDetails.dob | dateFormat}}</span></div>
                      </div>
                      <div class="dispflex" style="justify-content:flex-start">
                          <div class="lblitem"><label>Nationality:</label></div>
                          <div class="fielditem"><span>Indian</span></div>
                      </div>
                      <div class="dispflex" style="justify-content:flex-start">
                          <div class="lblitem"><label> {{ contactDetails.pointOfContact == 'Father' ? 'Father' : 'Mother' }} Name:</label></div>
                          <div class="fielditem"><span>{{ contactDetails.parent ? ( contactDetails.parent.firstName + " " +  contactDetails.parent.lastName ) : '' }}</span></div>
                      </div>
                      
                      <div class="dispflex" style="justify-content:flex-start">
                          <div class="lblitem"><label>Gender:</label></div>
                          <div class="fielditem"><span>{{ contactDetails.gender }}</span></div>
                      </div>
                      <div class="dispflex" style="justify-content:flex-start">
                          <div class="lblitem"><label>Last day at School:</label></div>
                          <div class="fielditem">
                              <input type="date" onkeydown="return false" 
                                v-model="onlineTcRequest.lastAttend"
                                disabled
                                name="deadLine" 
                                id="addlid" 
                                placeholder="Admission Deadline" >
                          </div>
                      </div>
                      <ol class="listnone">
                          <li>Date of Admission into the School: <span><b>{{ contactDetails.createdDate | dateFormat }}</b></span> Academic Year: <span><b> {{ contactDetails.academicYear ? ( contactDetails.academicYear.from + " " + contactDetails.academicYear.to ) : ''}} </b></span></li>
                          <li>The present class: <span><b>{{ contactDetails.class ? contactDetails.class.className : ''}}</b></span> Academic Year: <span><b> {{ contactDetails.academicYear ? ( contactDetails.academicYear.from + " " + contactDetails.academicYear.to ) : ''}}</b></span></li>
                      </ol>
                      <div class="dispflex" style="justify-content:flex-start">
                          <div class="lblitem"><label>Date of Leaving the School:</label></div>
                          <div class="fielditem">
                            <input disabled type="date" onkeydown="return false" 
                                v-model="onlineTcRequest.dateOfLeaving"
                                name="deadLine" 
                                id="addlid" 
                                placeholder="Admission Deadline" >
                          </div>
                      </div>
                      <div class="dispflex" style="justify-content:flex-start">
                          <div class="lblitem"><label>General Conduct:</label></div>
                          <div class="fielditem"><textarea disabled v-model="onlineTcRequest.goodContact" rows="4" cols="50"></textarea></div>
                      </div>
                      <div class="dispflex" style="justify-content:flex-start">
                          <div class="lblitem"><label>Any Fee Dues:</label></div>
                          <div class="fielditem"><span>Not Paid</span></div>
                      </div>
                      <div class="dispflex" style="justify-content:flex-start">
                          <div class="lblitem"><label>Reason for Leaving the School:</label></div>
                          <div class="fielditem"><textarea disabled v-model="onlineTcRequest.reasonForLeaving" rows="4" cols="50"></textarea></div>
                      </div>
                      <label><b>Promotional Details:</b></label>
                      <ol>
                        <li v-if="onlineTcRequest.promoteToClass"> Passed and Promoted to class:
                               <!-- <input disabled  
                                name="deadLine" 
                                id="addlid" 
                                :value="getclassName(onlineTcRequest.promoteToClass)" > -->

                          <select
                            disabled
                            name="AcademicYear"
                            id="deptlistid"
                            v-model="onlineTcRequest.promoteToClass"
                          >
                            <option :value="null">--Select--</option>
                          <option
                              v-for="(item, index) in classList"
                              :key="index"
                              :value="item._id"
                            >
                              {{ item.className }}  
                            </option>
                          </select> 
                          
                          for academic year: 
                           <!-- <input disabled  
                                name="deadLine" 
                                id="addlid" 
                                :value="getAcademicYearName(onlineTcRequest.promoteToYear)" > -->
                            <select
                            disabled
                            name="AcademicYear"
                            id="deptlistid"
                            v-model="onlineTcRequest.promoteToYear"
                          >
                            <option :value="null">--Select--</option>
                            <option
                              v-for="(item, index) in academicYearList"
                              :key="index"
                              :value="item._id"
                            >
                              {{ item.from }} - {{ item.to }}
                            </option>
                          </select>
                        </li>
                        <li v-if="onlineTcRequest.detainedToClass">Detained in Class: 
                           <!-- <input disabled  
                                name="deadLine" 
                                id="addlid" 
                                :value="getclassName(onlineTcRequest.detainedToClass)" > -->

                          <select
                            disabled
                            name="AcademicYear"
                            id="deptlistid"
                            v-model="onlineTcRequest.detainedToClass"
                          >
                            <option :value="null">--Select--</option>
                            <option
                              v-for="(item, index) in classList"
                              :key="index"
                              :value="item._id"
                            >
                              {{ item.className }}  
                            </option>
                          </select> 

                          for academic year: 
                           <!-- <input disabled  
                                name="deadLine" 
                                id="addlid" 
                                :value="getAcademicYearName(onlineTcRequest.detainedToYear)" > -->
                          <select
                            disabled
                            name="AcademicYear"
                            id="deptlistid"
                            v-model="onlineTcRequest.detainedToYear"
                          >
                            <option :value="null">--Select--</option>
                            <option
                              v-for="(item, index) in academicYearList"
                              :key="index"
                              :value="item._id"
                            >
                              {{ item.from }} - {{ item.to }}
                            </option>
                          </select> 

                          </li>
                      </ol>
                      <p class="alert alert-info p-1"><b>Certified that the above information / particulars is as per the school admission records</b></p>
                       <div class="dispflex pt-4 pb-4">
                <div>
                  <label>Date: </label><span>{{ new Date() | dateFormat }}</span
                  ><br /><br />
                  <label>Name: </label><span><input class="form-control form-input" name="deadLine" id="addlid" ></span>
                </div>
                <div>
                  <br /><br />
                  <label style="text-align: center"
                    >Signature of <br />
                    Class Teacher
                  </label>
                </div>
                <div>
                  <br /><br />
                  <label style="text-align: center"
                    >Checked by<br /><span style="margin-left: 20%;"><input class="form-control form-input" name="deadLine" id="addlid" placeholder="full Name and Designation"></span>
                  </label>
                </div>
                <div>
                  <br /><br />

                  <img
                    v-if="
                      schoolDetails.signature && schoolDetails.signature.path
                    "
                    :src="schoolDetails.signature.path"
                    alt="Avatar"
                    width="80"
                    height="80"
                  />
                  <label style="text-align: center"
                    >Signature of principal<br />with official seal
                  </label>
                  <img
                    v-if="
                      schoolDetails.schoolSeal && schoolDetails.schoolSeal.path
                    "
                    :src="schoolDetails.schoolSeal.path"
                    alt="Avatar"
                    width="80"
                    height="80"
                  />
                </div>
              </div>
                  </div>
                </div>
              </div>
              
          </div>
          <div
            class="pt-3 text-right dk_iconsml"
            style="border-top: 1px solid #e5e5e5"
          >
          <!-- <button
              type="button"
              class="btn btnsml"
              id="svbtn"
              @click.prevent="updateDetails"
            >
                <span>Update</span>
            </button> -->
            <button
              type="button"
              class="btn btnsml"
              id="no-print"
              @click.prevent="print"
            >
                <span>Print</span>
            </button>
            <button  @click.prevent="closePopUp" type="button" class="btn btncl clsmdl" id="no-print">
                 <span>Cancel</span>
            </button>
          </div>

    </b-modal>
     <b-modal id="previewCC">
      <div class="row mt-3">
        <div class="col-lg-12">
          <div class="widjet py-3 pl-3 pr-3">
            <div class="row">
              <div class="col-md-3 text-center">
                <img
                  width="200"
                  height="120"
                  v-if="schoolDetails.image && schoolDetails.image.path"
                  :src="schoolDetails.image.path"
                  :alt="schoolDetails.accountName"
                  :title="schoolDetails.accountName"
                />
                <img
                  v-else
                  width="200"
                  height="120"
                  :src="schoolDetails.image"
                  :alt="schoolDetails.accountName"
                  :title="schoolDetails.accountName"
                />

                <!-- <img src="../../assets/img/velammalLogo.png"> -->
              </div>
              <div class="col-md-9 text-center">
                <h1>{{ schoolDetails.accountName }}</h1>
                <p>
                  {{ schoolDetails.address }}, {{ schoolDetails.street }},
                  {{ schoolDetails.district ? schoolDetails.district : "" }},
                  {{ schoolDetails.state ? schoolDetails.state : "" }},
                  {{ schoolDetails.pincode }}, {{ schoolDetails.country
                  }}<br /><b>Affiliate No. 223434534</b> <br /><b
                    v-if="schoolDetails.landlineNo"
                    >P : </b
                  >
                  {{
                    schoolDetails.landlineNo
                      ? "044 -" + " " + schoolDetails.landlineNo
                      : ""
                  }}
                  | <b>E :</b> <a
                    v-if="schoolDetails.email"
                    href="mailto:info@sairamgroup.in"
                    >{{ schoolDetails.email }}</a
                  >
                  | <b>W : </b
                  ><a
                    :href="schoolDetails.website"
                    target="_black"
                    v-if="schoolDetails.website"
                    >{{ schoolDetails.website }}</a
                  >
                </p>
              </div>
            </div>
            <div class="dispflex p-2 mt-2" style="background: #f7f7f7">
              <div style="width: 15%">
                <b>Date : {{ new Date() | dateFormat }} </b>
              </div>
              <b>CONDUCT CERTIFICATE</b>
              <div style="width: 25%">
                <b>Application No: <input disabled v-model="contactDetails.rollNoAllocated" name="deadLine" id="addlid" ></b>
              </div>
            </div>
            <div class="py-3 onlinetc">
              <p>
                This is certifiy that
                <span
                  >{{
                    contactDetails.firstName + " " + contactDetails.lastName
                  }}
                </span>
                {{ contactDetails.gender == "Male" ? "Son" : "Daughter" }} Of
                <span>{{
                  contactDetails.parent
                    ? contactDetails.parent.firstName +
                      " " +
                      contactDetails.parent.lastName
                    : ""
                }}</span>

                is/was student of Class
                <span
                  ><b>{{
                    contactDetails.class ? contactDetails.class.className : ""
                  }}</b></span
                >

                of this School during the Academic Year
                <span
                  ><b>
                    {{
                      contactDetails.academicYear
                        ? contactDetails.academicYear.from +
                          " - " +
                          contactDetails.academicYear.to
                        : ""
                    }}</b
                  ></span
                >

                and that
                {{ contactDetails.gender == "Male" ? "her" : "his" }} conduct
                and character are / have been
                <span
                  ><b>{{ previewConduct }}</b></span
                >
              </p>

              <p class="alert alert-info p-1">
                <b
                  >Certified that the above information / particulars is as per
                  the school admission records</b
                >
              </p>
              <div class="dispflex pt-4 pb-4">
                <div>
                  <label>Date: </label><span>{{ new Date() | dateFormat }}</span
                  ><br /><br />
                  <label>Name: </label><span><input class="form-control form-input" name="deadLine" id="addlid" ></span>
                </div>
                <div>
                  <br /><br />
                  <label style="text-align: center"
                    >Signature of <br />
                    Class Teacher
                  </label>
                </div>
                <div>
                  <br /><br />
                  <label style="text-align: center"
                    >Checked by<br /><span style="margin-left: 20%;"><input class="form-control form-input" name="deadLine" id="addlid" placeholder="full Name and Designation"></span>
                  </label>
                </div>
                <div>
                  <br /><br />

                  <img
                    v-if="
                      schoolDetails.signature && schoolDetails.signature.path
                    "
                    :src="schoolDetails.signature.path"
                    alt="Avatar"
                    width="80"
                    height="80"
                  />
                  <label style="text-align: center"
                    >Signature of principal<br />with official seal
                  </label>
                  <img
                    v-if="
                      schoolDetails.schoolSeal && schoolDetails.schoolSeal.path
                    "
                    :src="schoolDetails.schoolSeal.path"
                    alt="Avatar"
                    width="80"
                    height="80"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="pt-3 text-right dk_iconsml"
        style="border-top: 1px solid #e5e5e5"
      >
       <button 
              type="button"
              class="btn btnsml"
              id="no-print"
               @click="printPreviewCC"
            >
                <span>Print</span>
            </button>
        <button
          @click.prevent="closePreviewCCPopUp"
          type="button"
          class="btn btncl clsmdl"
          id="no-print"
        >
             <span>Cancel</span>
        </button>
      </div>
    </b-modal>
    <b-modal id="previewBC">
      <div class="row mt-3">
        <div class="col-lg-12">
          <div class="widjet py-3 pl-3 pr-3">
            <div class="row">
              <div class="col-md-3 text-center">
                <img
                  width="200"
                  height="120"
                  v-if="schoolDetails.image && schoolDetails.image.path"
                  :src="schoolDetails.image.path"
                  :alt="schoolDetails.accountName"
                  :title="schoolDetails.accountName"
                />
                <img
                  v-else
                  width="200"
                  height="120"
                  :src="schoolDetails.image"
                  :alt="schoolDetails.accountName"
                  :title="schoolDetails.accountName"
                />
              </div>
              <div class="col-md-9 text-center">
                <h1>{{ schoolDetails.accountName }}</h1>
                <p>
                  {{ schoolDetails.address }}, {{ schoolDetails.street }},
                  {{ schoolDetails.district ? schoolDetails.district : "" }},
                  {{ schoolDetails.state ? schoolDetails.state : "" }},
                  {{ schoolDetails.pincode }}, {{ schoolDetails.country
                  }}<br /><b>Affiliate No. 223434534</b> <br /><b
                    v-if="schoolDetails.landlineNo"
                    >P : </b
                  >
                  {{
                    schoolDetails.landlineNo
                      ? "044 -" + " " + schoolDetails.landlineNo
                      : ""
                  }}
                  | <b>E :</b> <a
                    v-if="schoolDetails.email"
                    href="mailto:info@sairamgroup.in"
                    >{{ schoolDetails.email }}</a
                  >
                  | <b>W : </b
                  ><a
                    :href="schoolDetails.website"
                    target="_black"
                    v-if="schoolDetails.website"
                    >{{ schoolDetails.website }}</a
                  >
                </p>
              </div>
            </div>
            <div class="dispflex p-2 mt-2" style="background: #f7f7f7">
              <div style="width: 15%">
                <b>Date : {{ new Date() | dateFormat }} </b>
              </div>
              <b>BONAFIDE CERTIFICATE</b>
              <div style="width: 25%">
                <b>Application No: <input disabled v-model="contactDetails.rollNoAllocated" name="deadLine" id="addlid" ></b>
              </div>
            </div>
            <div class="py-3 onlinetc">
              <p>
                This is certifiy that
                <span
                  >{{
                    contactDetails.firstName + " " + contactDetails.lastName
                  }}
                </span>
                {{ contactDetails.gender == "Male" ? "Son" : "Daughter" }} Of
                <span>{{
                  contactDetails.parent
                    ? contactDetails.parent.firstName +
                      " " +
                      contactDetails.parent.lastName
                    : ""
                }}</span>

                is/was Bonafide student studying in class
                <span
                  ><b>{{
                    contactDetails.class ? contactDetails.class.className : ""
                  }}</b></span
                >

                of this School during the Academic Year
                <span
                  ><b>
                    {{
                      contactDetails.academicYear
                        ? contactDetails.academicYear.from +
                          " - " +
                          contactDetails.academicYear.to
                        : ""
                    }}</b
                  ></span
                >
                {{ contactDetails.gender == "Male" ? "Her" : "His" }} Date of
                Birth is
                <span
                  ><b> {{ contactDetails.dob | dateFormat }}</b></span
                >
                and Place of Birth is <span><b></b></span>
              </p>

              <p class="alert alert-info p-1">
                <b
                  >Certified that the above information / particulars is as per
                  the school admission records</b
                >
              </p>
              <div class="dispflex pt-4 pb-4">
                <div>
                  <label>Date: </label><span>{{ new Date() | dateFormat }}</span
                  ><br /><br />
                  <label>Name: </label><span><input class="form-control form-input" name="deadLine" id="addlid" ></span>
                </div>
                <div>
                  <br /><br />
                  <label style="text-align: center"
                    >Signature of <br />
                    Class Teacher
                  </label>
                </div>
                <div>
                  <br /><br />
                  <label style="text-align: center"
                    >Checked by<br /><span style="margin-left: 20%;"><input class="form-control form-input" name="deadLine" id="addlid" placeholder="full Name and Designation"></span>
                  </label>
                </div>
                <div>
                  <img
                    v-if="
                      schoolDetails.signature && schoolDetails.signature.path
                    "
                    :src="schoolDetails.signature.path"
                    alt="Avatar"
                    width="80"
                    height="80"
                  />
                  <br />
                  <label style="text-align: center"
                    >Signature of principal<br />with official seal
                  </label>
                  <br />
                  <img
                    v-if="
                      schoolDetails.schoolSeal && schoolDetails.schoolSeal.path
                    "
                    :src="schoolDetails.schoolSeal.path"
                    alt="Avatar"
                    width="80"
                    height="80"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="pt-3 text-center dk_iconsml"
        style="border-top: 1px solid #e5e5e5"
      >
       <button
              type="button"
              class="btn btnsml"
              id="no-print"
              @click="printPreviewBC"
              
            >
                <span>Print</span>
            </button>
        <button
          @click.prevent="closePreviewBCPopUp"
          type="button"
          class="btn btncl clsmdl"
          id="no-print"
        >
             <span>Cancel</span>
        </button>
      </div>
    </b-modal>
</div>
    <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
</div>
</template>
<script>

import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";

export default {
  name: "vi-admission-home",
  
  data() {
    return {
      viLoader: false,
      previewConduct: '',
      tcCount: 0,
      bcCount: 0,
      ccCount: 0,
      onlineTcRequest: {
        type: null,
        schoolName: '',
        address: '',
        reason: '',
        comments: '',
        statusComments: '',
        status: '',
        state: '',
        document : {
          path: '',
          key: ''
        },
        country: '',
        studentId: this.$route.params.id,
        lastAttend : null,
        dateOfLeaving : null,
        goodContact : "",
        dueFees : "",
        reasonForLeaving : "",
        promoteToClass : null,
        promoteToYear : null,
        detainedToClass : null,
        detainedToYear : null,
	  },
    optionList: [],
    selectOption: '',
    certificateList: [],
	  schoolDetails: {},
	  contactDetails: {},
	  academicYearList: [],
    classList: [],
    showContent: false,
	  editId: null,
	  typeList: [ 'Internal School Transfer','Other School' ],
	  statusList: ['Draft','Submitted','Approved','Rejected','Generated','Online','Offline','Couriered','In-Person Collect','Need further Info','Verified'],
	  reasonList: ['Location Change','School Change']
    };
  },
  created() {
	this.getOnlineTc()
  
  },
  computed: {
      styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      },
  },
  methods: {
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",this.$route.path);
      this.$router.push({ path: data });
    },
    redirectRoute(vipath) {
      if(this.$route.path !== vipath) {
        this.$router.push({ path: vipath })
      };
    },
	 closePopUp(){
     this.$bvModal.hide("printAction");
    },
     preViewDocument(path) {
      window.open(path, "_blank");
    },
	async print() {
      const modal = document.getElementById("printAction")
      const cloned = modal.cloneNode(true);
      let section = document.getElementById("printSection");

      if (!section) {
        section = document.createElement("div");
        section.id = "printSection";
        document.body.appendChild(section);
      }

      section.innerHTML = "";
      section.appendChild(cloned);
      window.print();
       
    },
	async printPreview(index) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        const details = this.certificateList[index];
       
        if (details && details.studentId) {
          const response = await ViService.viXGet(
            `/contact/getStudentTcPrintDetails?studentId=${details.studentId}`,
            userData.token
          );
          if (response.isSuccess) {
            const resultData = secureUI.secureGet(response.data);

            this.schoolDetails = resultData.school || {},
            this.contactDetails = resultData.student || {}

            if(details.certificateType == 'Transfer Certificate'){
            this.onlineTcRequest.lastAttend = details.lastAttend ? details.lastAttend.substring(0, 10): null;
            this.onlineTcRequest.dateOfLeaving = details.dateOfLeaving ? details.dateOfLeaving.substring(0, 10) : null;
            this.onlineTcRequest.goodContact = details.goodContact;
            this.onlineTcRequest.dueFees = details.dueFees;
            this.onlineTcRequest.reasonForLeaving = details.reasonForLeaving;
            this.onlineTcRequest.promoteToClass = details.promoteToClass;
            this.onlineTcRequest.promoteToYear = details.promoteToYear;
            this.onlineTcRequest.detainedToClass = details.detainedToClass;
            this.onlineTcRequest.detainedToYear = details.detainedToYear;
            this.onlineTcRequest._id = details._id;

       
            this.$bvModal.show("printAction");
            }

            if(details.certificateType == 'Conduct Certificate'){
              this.previewConduct = details.previewConduct
              this.$bvModal.show("previewCC");


            }
            if(details.certificateType == 'Bonafide Certificate'){
              this.$bvModal.show("previewBC");
            }
           
          }
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }

    
    },
     async printPreviewCC() {

      const modal = document.getElementById("previewCC");

      const cloned = modal.cloneNode(true);

      let section = document.getElementById("printSection");

      if (!section) {
        section = document.createElement("div");
        section.id = "printSection";
        document.body.appendChild(section);
      }

      section.innerHTML = "";
      section.appendChild(cloned);
      window.print();
     
    },
     async printPreviewBC() {

      const modal = document.getElementById("previewBC");

      const cloned = modal.cloneNode(true);

      let section = document.getElementById("printSection");

      if (!section) {
        section = document.createElement("div");
        section.id = "printSection";
        document.body.appendChild(section);
      }

      section.innerHTML = "";
      section.appendChild(cloned);
      window.print();
     
    },
    getclassName(id){
      const details = this.classList.find( x => x._id == id)
      if(details){
        return details.className
      }else{
        return ''
      }
    },
     getAcademicYearName(id){
      const details = this.academicYearList.find( x => x._id == id)
      if(details){
        return details.from + " " + details.to
      }else{
        return ''
      }
    },
    
	async getOnlineTc() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        this.optionList = []
        this.viLoader = true
        const response = await ViService.viXGet(`/contact/getOnlineCertificateRequestDetails?studentId=${this.$route.params.id}`,userData.token);
     
	     if (response.isSuccess) {
        const onlineTcDetails = secureUI.secureGet(response.data);
        
        this.certificateList = onlineTcDetails.requestList || []
        this.classList = onlineTcDetails.classList || []
        this.academicYearList = onlineTcDetails.acdList || []

        this.tcCount = this.certificateList.filter( x => x.certificateType == 'Transfer Certificate').length
        this.bcCount = this.certificateList.filter( x => x.certificateType == 'Bonafide Certificate').length
        this.ccCount = this.certificateList.filter( x => x.certificateType == 'Conduct Certificate').length
        
        if(this.tcCount == 0){
          this.optionList.push('Transfer Certificate')
        }
        if(this.bcCount == 0){
          this.optionList.push('Bonafide Certificate')
        }
        if(this.ccCount == 0){
          this.optionList.push('Conduct Certificate')
        }
			
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
        this.viLoader = false
      }
  },
	async getSchoolDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(`/contact/getStudentSchoolDetails?studentId=${this.$route.params.id}`,userData.token);
        if (response.isSuccess) {
            const studentDetails = secureUI.secureGet(response.data);
            this.onlineTcRequest.schoolName = studentDetails.accountName,
            this.onlineTcRequest.address = studentDetails.address,
            this.onlineTcRequest.state = studentDetails.state,
            this.onlineTcRequest.country = studentDetails.country
			
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
  },
	cancelPopUp(){
      this.$bvModal.hide("openPopup");
	},
	editPopUp(index){
     const studentDetails = this.certificateList[index];

      const cloneStudentDetails = { ...studentDetails };
    
      if(cloneStudentDetails.certificateType == 'Transfer Certificate'){
        this.onlineTcRequest.statusComments = cloneStudentDetails.statusComments
        this.onlineTcRequest.comments = cloneStudentDetails.comments
        this.onlineTcRequest.status = cloneStudentDetails.status
        this.onlineTcRequest.reason = cloneStudentDetails.reason
        this.onlineTcRequest.type = cloneStudentDetails.type
        this.onlineTcRequest.schoolName = cloneStudentDetails.schoolName
        this.onlineTcRequest.document.path = cloneStudentDetails.document ? cloneStudentDetails.document.path : "";
        this.onlineTcRequest.document.key = cloneStudentDetails.document ? cloneStudentDetails.document.key : "";

      }

      this.editId = cloneStudentDetails._id;

      this.$bvModal.show("openPopup");
	},
  showCertModel(type){
    if(type){
        if(type == 'Transfer Certificate'){
          this.onlineTcRequest.status = 'Draft'
          this.$bvModal.show("openPopup");
          this.getSchoolDetails()
        }else if(type == 'Bonafide Certificate'){
          this.$bvModal.show("BCPopUp");
        }else{
          this.$bvModal.show("CCPopUp");
        }
    }
    
  },
	// showTCModel(){
  //     this.onlineTcRequest.status = 'Draft'
	//     this.$bvModal.show("openPopup");
  //     this.getSchoolDetails()
	// },
  // showCCModel(){
	//     this.$bvModal.show("CCPopUp");
	// },
  // showBCModel(){
	//     this.$bvModal.show("BCPopUp");
	// },
   closeCCPopUp(){
     this.$bvModal.hide("CCPopUp");
    },
     closeBCPopUp(){
     this.$bvModal.hide("BCPopUp");
    },
     async previewDetails(studentId, Type) {
    

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/contact/getStudentTcPrintDetails?studentId=${studentId}`,
          userData.token
        );
        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);

          (this.schoolDetails = resultData.school || {}),
            (this.contactDetails = resultData.student || {});
        }

       

        if (Type == "Conduct Certificate") {
          this.$bvModal.show("previewCC");
        }
        if (Type == "Bonafide Certificate") {
          this.$bvModal.show("previewBC");
        }
         if (Type == "Transfer Certificate") {
            this.tcRequest.lastAttend = this.onlineTcRequest.lastAttend ? this.onlineTcRequest.lastAttend.substring(0, 10): null;
            this.tcRequest.dateOfLeaving = this.onlineTcRequest.dateOfLeaving ? this.onlineTcRequest.dateOfLeaving.substring(0, 10) : null;
            this.tcRequest.goodContact = this.onlineTcRequest.goodContact;
            this.tcRequest.dueFees = this.onlineTcRequest.dueFees;
            this.tcRequest.reasonForLeaving = this.onlineTcRequest.reasonForLeaving;
            this.tcRequest.promoteToClass = this.onlineTcRequest.promoteToClass;
            this.tcRequest.promoteToYear = this.onlineTcRequest.promoteToYear;
            this.tcRequest.detainedToClass = this.onlineTcRequest.detainedToClass;
            this.tcRequest.detainedToYear = this.onlineTcRequest.detainedToYear;
            this.$bvModal.show("printAction");
        }
      }
    },
    closePreviewBCPopUp() {
      this.$bvModal.hide("previewBC");
    },
     closePreviewCCPopUp() {
      this.$bvModal.hide("previewCC");
    },
    
   
	async createOnlineTc() {
        let isFormValid = false;

        await this.$validator.validateAll('academicyr').then(result => {
          isFormValid = result;
        });
        if(isFormValid) {
			let userData = secureUI.sessionGet("user");

			if (!userData) {
				this.$toasted.error("Please login and do the action");
				this.$router.push('/login');
			} else {
				this.onlineTcRequest.status = 'Draft'

				const response = await ViService.viXPost("/contact/createOnlineTc",this.onlineTcRequest,userData.token);

				if (response.isSuccess) {
					this.$bvModal.hide("openPopup");
					this.getOnlineTc()
					this.$toasted.success(response.message);
				} else {
					this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
				}
				
			}
        }
        
		
    },
    async createRequestAction(type) {
      	let userData = secureUI.sessionGet("user");

       if (!userData) {
				this.$toasted.error("Please login and do the action");
				this.$router.push('/login');
			} else {
				let obj = {
          certificateType: type,
          requestCreatedBy: 'Parent',
          studentId: this.$route.params.id
        }

				const response = await ViService.viXPost("/contact/createCertificateRequest/parent",obj,userData.token);
        
				if (response.isSuccess) {
					this.closeCCPopUp()
          this.closeBCPopUp()
          this.getOnlineTc()
          this.selectOption = ''
					this.$toasted.success(response.message);
				} else {
					this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
				}
      }
				
			
    },
	async updateOnlineTc() {
        let isFormValid = false;

        await this.$validator.validateAll('academicyr').then(result => {
          isFormValid = result;
        });
        if(isFormValid) {
			let userData = secureUI.sessionGet("user");

			if (!userData) {
				this.$toasted.error("Please login and do the action");
				this.$router.push('/login');
			} else {
				

        this.onlineTcRequest.status = 'Submitted'

         if(this.onlineTcRequest.studentId){
           delete this.onlineTcRequest.studentId
         }


        const response = await ViService.viXPut(
          `/contact/updateOnlineTcRequest/${this.editId}`,
          this.onlineTcRequest,
          userData.token
        );

				if (response.isSuccess) {
					this.$bvModal.hide("openPopup");
					this.getOnlineTc()
					this.$toasted.success(response.message);
				} else {
					this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
				}
				
			}
        }
        
		
    },
	
    
    
  },
  components: {
    ViSpinner
  }
  
};
</script>

<style>
@media screen {
  #printSection {
    
      display: none;
  }
}

@media print {
  body * {
    visibility:hidden;
  }
  #printSection, #printSection * {
    visibility:visible;
  }
  #printSection {
    position:absolute;
    left:0;
    top:0;
  }
  #no-print,
  #no-print * {
    display: none !important;
  }
}
</style>